import { render, staticRenderFns } from "./HeldenAd.vue?vue&type=template&id=fbf407ea&scoped=true&lang=pug&"
import script from "./HeldenAd.vue?vue&type=script&lang=ts&"
export * from "./HeldenAd.vue?vue&type=script&lang=ts&"
import style0 from "./HeldenAd.vue?vue&type=style&index=0&id=fbf407ea&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fbf407ea",
  null
  
)

export default component.exports